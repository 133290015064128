var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entry-content"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('div',{staticClass:"container"},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(0),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('My_Account')))])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(_vm._s(_vm.$t('my_info')))])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('ul',{staticClass:"menu-user"},[_c('li',[_c('a',{attrs:{"href":""}},[_vm._m(1),_vm._v(" "+_vm._s(_vm.$t('my_info')))])]),_c('li',[_c('a',{attrs:{"href":""}},[_vm._m(2),_vm._v(" "+_vm._s(_vm.$t('The_date_of_order')))])]),_c('li',[_c('a',{attrs:{"href":""}},[_vm._m(3),_vm._v(" "+_vm._s(_vm.$t('my_addresses')))])]),_c('li',[_c('a',{attrs:{"href":""}},[_vm._m(4),_vm._v(" "+_vm._s(_vm.$t('change_Password')))])]),_c('li',[_c('a',{attrs:{"href":""}},[_vm._m(5),_vm._v(" "+_vm._s(_vm.$t('sign_out')))])])])]),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"entry-content-myaccount order-details"},[_c('h2',[_vm._v(_vm._s(_vm.$t('Order_history')))]),_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('photo')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('quantity')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('total_summation')))])])]),_vm._m(6)]),_c('h2',[_vm._v(_vm._s(_vm.$t('Shipping_place')))]),_c('div',{staticClass:"box address"},[_c('strong',[_c('i',{staticClass:"fas fa-map-marker-alt"}),_vm._v(" "+_vm._s(_vm.$t('address')))]),_vm._m(7)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bi bi-house-door"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/main/assets/images/user-icon.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/main/assets/images/history.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/main/assets/images/address.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/main/assets/images/006-close-envelope.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/main/assets/images/logout.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('tr',[_c('td',[_c('figure',{staticClass:"mb-0"},[_c('img',{attrs:{"src":require("@/main/assets/images/54c5989ec7bc8b192c60f9e9a0dae937.jpg"),"alt":""}})])]),_c('td',{attrs:{"data-title":"الاسم "}},[_vm._v("مصحف اسلامي ذهبي من الطراز الفاخر")]),_c('td',{attrs:{"data-title":"الكمية "}},[_vm._v("2")]),_c('td',{attrs:{"data-title":"السعر "}},[_vm._v("1300 $")])]),_c('tr',[_c('td',[_c('figure',{staticClass:"mb-0"},[_c('img',{attrs:{"src":require("@/main/assets/images/54c5989ec7bc8b192c60f9e9a0dae937.jpg"),"alt":""}})])]),_c('td',{attrs:{"data-title":"الاسم "}},[_vm._v("مصحف اسلامي ذهبي من الطراز الفاخر")]),_c('td',{attrs:{"data-title":"الكمية "}},[_vm._v("2")]),_c('td',{attrs:{"data-title":"السعر "}},[_vm._v("1300 $")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("السعودية - المدينة المنورة - شارع الملك عبد الله")]),_c('li',[_vm._v("السعودية - المدينة المنورة - شارع الملك عبد الله")]),_c('li',[_vm._v("السعودية - المدينة المنورة - شارع الملك عبد الله")])])
}]

export { render, staticRenderFns }